import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/ebr_seite.png'
import HeaderSocial from './HeaderSocials'
import zuehlke from '../../assets/Zuehlke_Logo_rgb_small.jpg'



function Header() {
  return (
   <header>
     <div className="container header__container">
       <h5>Hallo, Ich bin</h5>
       <h1>Elias Bräm</h1>
       <h5 className='text-light'>Junior Software Entwickler</h5>
      <CTA />
      <HeaderSocial />
      <div className='you'>
      <img src={zuehlke} alt="Zuehlke Logo" />
      </div>
      
<div className='me'>
      <img src={ME} alt="Elias Braem" />
      </div>
      <div className='header__scroll'>
        <p>Scroll Down</p>
    </div>
      
     </div>
   </header>
  )
}

export default Header